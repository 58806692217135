import React, { useEffect, useState } from "react";
import "../components/Bildirimler/Bildirimler.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import HomeMenu from "../components/HomePage/HomeMenu/HomeMenu";
import HomeSss from "../components/HomePage/HomeSss/HomeSss";
import HomeFooter from "../components/HomePage/HomeFooter/HomeFooter";
import {
  AdvancedRealTimeChart,
  StockMarket,
} from "react-ts-tradingview-widgets";
function Tradingview() {
  useEffect(() => {
    document.title = "Tradingview - Sirius Algo";
  }, []);
  return (
    <React.Fragment>
      <div className="HomePage">
        <HomeMenu />
        <Container>
          <Row style={{ backgroundColor: "#ffffff00", padding: 50 }}>
            <Col style={{ color: "white" }}>
              <h1 style={{ color: "white" }}>TradingView</h1>
              <AdvancedRealTimeChart locale="tr" />
              <p style={{ color: "white" }}>
                Eğer bütçenizi aşmayan ve uygun maliyetli çözümler arıyorsanız,
                en iyi ücretsiz{" "}
                <a
                  style={{ color: "#7016d0" }}
                  href="https://tr.tradingview.com/screener/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  hisse senedi tarayıcıları
                </a>{" "}
                hakkında değerli bilgiler edinmek isteyebilirsiniz.{" "}
                <a
                  style={{ color: "#7016d0" }}
                  href="https://tr.tradingview.com/symbols/DXY"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Dolar Endeksi
                </a>{" "}
                alımı için adım adım rehberimize göz atın ve yatırım
                portföyünüzü çeşitlendirmek için izlemeniz gereken stratejileri
                keşfedin. Ayrıca, ekonomi takvimini nasıl etkili bir şekilde
                takip edebileceğinizi anlamak ve öngörü gücünden nasıl
                faydalanabileceğinizi öğrenmek için içgörüler elde edin.
              </p>{" "}
              <StockMarket
                colorTheme="dark"
                height={400}
                width="100%"
                exchange="BIST"
                locale="tr"
              ></StockMarket>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <Button variant="primary">
                  {" "}
                  <a
                    href="https://tr.tradingview.com/widget/"
                    target="_blank"
                    class="button"
                    rel="noopener"
                    style={{ color: "white" }}
                  >
                    TradingView
                  </a>{" "}
                </Button>
                <p style={{ color: "white" }} class="message">
                  Daha fazla bilgi için tıklayınız.
                </p>{" "}
              </div>
            </Col>
          </Row>
        </Container>
        <HomeSss />
        <HomeFooter />
      </div>
    </React.Fragment>
  );
}

export default Tradingview;
