import React from "react";
import "./HomeMenu.css";
import { useSelector } from "react-redux";
import logo from "../../../assets/img/sirius-logo.svg";
import InstagramLogo from "../../svgs/instagram";
import ThreadsLogo from "../../svgs/threads";
import YoutubeLogo from "../../svgs/youtube";
import XLogo from "../../svgs/x";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
const HomeMenu = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [activeMenu, setActiveMenu] = React.useState(0);

  const handleMouseOver = (index) => {
    return () => {
      setActiveMenu(index);
    };
  };
  const handleMouseOut = (index) => {
    return () => {
      setActiveMenu(0);
    };
  };
  return (
    <Container className="HomeMenu">
      <Navbar expand="lg">
        <Navbar.Brand className="HomeMenu__logo">
          <Nav.Link href="/">
            <img src={logo} className="HomeMenu__logo__img" alt="logo" />
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="main-menu" />
        <Navbar.Collapse id="main-menu">
          <div className="HomeMenu__list">
            <Nav className="HomeMenu__list__ul">
              <li
                onMouseOver={handleMouseOver(0)}
                onMouseOut={handleMouseOut}
                className={activeMenu == 0 ? "HomeMenu__list__active" : ""}
              >
                <Nav.Link href="#hakkimizda">Hakkımızda</Nav.Link>
              </li>
              <li
                onMouseOver={handleMouseOver(1)}
                onMouseOut={handleMouseOut}
                className={activeMenu == 1 ? "HomeMenu__list__active" : ""}
              >
                <Nav.Link href="#neler">Sizi Neler Bekliyor</Nav.Link>
              </li>
              <li
                onMouseOver={handleMouseOver(4)}
                onMouseOut={handleMouseOut}
                className={activeMenu == 4 ? "HomeMenu__list__active" : ""}
              >
                <Nav.Link href="#video">Eğitim Odası</Nav.Link>
              </li>
              <li
                onMouseOver={handleMouseOver(5)}
                onMouseOut={handleMouseOut}
                className={activeMenu == 5 ? "HomeMenu__list__active" : ""}
              >
                <Nav.Link href="#planlar">Planlar</Nav.Link>
              </li>
              <li
                onMouseOver={handleMouseOver(2)}
                onMouseOut={handleMouseOut}
                className={activeMenu == 2 ? "HomeMenu__list__active" : ""}
              >
                <Nav.Link href="#kesfet">Keşfetmeye Hazır Olun</Nav.Link>
              </li>
              <li
                onMouseOver={handleMouseOver(3)}
                onMouseOut={handleMouseOut}
                className={activeMenu == 3 ? "HomeMenu__list__active" : ""}
              >
                <Nav.Link href="#sss">S.S.S</Nav.Link>
              </li>
            </Nav>
          </div>
          <div className="HomeMenu__right">
            <ul className="HomeMenu__social">
              <li>
                <XLogo className="HomeMenu__social_img" />
              </li>
              <li>
                <ThreadsLogo className="HomeMenu__social_img" />
              </li>
              <li>
                <InstagramLogo className="HomeMenu__social_img" />
              </li>
              <li>
                <YoutubeLogo className="HomeMenu__social_img" />
              </li>
            </ul>
            {isAuthenticated ? (
              <Link to="/ana-kumanda" className="HomeMenu__button">
                Ana Kumanda
              </Link>
            ) : (
              <Link to="/uye-giris" className="HomeMenu__button">
                Giriş Yap/ Kayıt Ol
              </Link>
            )}
          </div>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};

export default HomeMenu;
