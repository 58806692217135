import React, { useEffect, useState } from "react";
import "./ListeFavori.css";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { FaTrash } from "react-icons/fa6";
import { BsStars } from "react-icons/bs";
import { postDataWithHeaders } from "../../utils/apiUtils";
import SonucTekEkle from "../../components/svgs/sonucTekEkle";
import { PulseLoader } from "react-spinners";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  ADDLISTE,
  MARKETS,
  GETLISTE,
  UPDATELISTE,
  DELETELISTE,
  TARAMA,
  INDICATORS,
  TARAMAV2,
} from "../../utils/urls";
import { ToastContainer, toast } from "react-toastify";
import { BsSearch } from "react-icons/bs";
import ButtonClose from "../svgs/buttonClose";
import { dataBorsa } from "../../utils/dummyData";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import axios from "axios";
import SonucSec from "../svgs/sonucSec";
import SonucSecildi from "../svgs/sonucSecildi";
import ArrowSort from "../svgs/arrowSort";
import ArrowDown from "../svgs/arrowDown";
import ArrowUp from "../svgs/arrowUp";
import SonucGrafik from "../svgs/sonucGrafik";
import _ from "lodash";
import PuanComponent from "../puan/puan";
import ChartComponent from "../chart/chart";
import { timeout } from "../../utils/helperFunction";
import forinvest from "../../assets/img/forinvest.svg";
let date = new Date();
var timestamp = date.getTime();

const objectToArrayFilters = (object) => {
  const arr = Object.keys(object).map((key) => ({
    slug: key,
    label: object[key].label,
    sort: object[key].sort,
  }));

  return arr.sort((a, b) => a.sort - b.sort);
};
function countMarket(objs) {
  var result = _.countBy(objs.flatMap((obj) => obj.market));
  return result;
}
function objectLength(obj) {
  var result = 0;
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      // or Object.prototype.hasOwnProperty.call(obj, prop)
      result++;
    }
  }
  return result;
}
const zamansal = ["BAYZ", "BAYZ(YAKIN)", "GÖLGE", "ABC=DEF"];
function Listelerim() {
  const urlApi = localStorage.getItem("urlApi");
  const { listeId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme);
  const { user } = useSelector((state) => state.auth);
  const { filters } = useSelector((state) => state.filters);
  const dataFilters = filters;
  const [updatedDataFormations, setUpdatedDataFormations] = useState(
    objectToArrayFilters(dataFilters.formations)
  );
  const [updatedDataHacim, setUpdatedDataHacim] = useState(
    objectToArrayFilters(dataFilters.volumes)
  );
  const [updatedDataIndicators, setUpdatedDataIndicators] = useState(
    objectToArrayFilters(dataFilters.indicators)
  );
  const [updatedDataOrtalamalar, setUpdatedDataOrtalamalar] = useState(
    objectToArrayFilters(dataFilters.averages)
  );
  const updateDataAll = updatedDataHacim.concat(
    updatedDataIndicators,
    updatedDataOrtalamalar
  );
  const [dataFilter, setDataFilter] = useState([]);
  const [puanName, setPuanName] = useState("");
  const [loadChart, setLoadChart] = useState("formation");
  const [dataBody, setDataBody] = useState(null);
  const [dataBody2, setDataBody2] = useState(null);
  const [selectListe, setSelectListe] = useState(0);
  const [selectListeTab, setSelectListeTab] = useState(1);
  const [loadData, setLoadData] = useState(false);
  const [sortType, setSortType] = useState(1);
  const [sortTypeChange, setSortTypeChange] = useState("default");
  const [dataFormation, setDataFormation] = useState(null);
  const [dataIndicator, setDataIndicator] = useState([]);
  const [favoritesStocks, setFavoritesStocks] = useState([]);
  const [hisseType, setHisseType] = useState("Hissede");
  const [listeMainName, setListeMainName] = useState("");
  const [listeName, setListeName] = useState("");
  const [listeItem, setListeItem] = useState("");
  const [hisseAdet, setHisseAdet] = useState(0);
  const [formationId, setFormationId] = useState(0);
  const [taramaAdet, setTaramaAdet] = useState(0);
  const [stratejiAdet, setStratejiAdet] = useState(0);
  const [listeNameId, setListeNameId] = useState(0);
  const [listeler, setListeler] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [update, setUpdate] = useState(false);
  const [updateId, setUpdateId] = useState(0);
  const [search, setSearch] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [deleteId, setDeleteId] = useState(0);
  const [selectedBorsa, setSelectedBorsa] = useState("");
  const [selectedSembol, setSelectedSembol] = useState([]);
  const [selectedBorsaId, setSelectedBorsaId] = useState(1);
  const [markets, setMarkets] = useState([]);
  const [marketler, setMarketler] = useState([]);
  const [searchMarket, setSearchMarket] = useState([]);
  const [loadFilter, setLoadFilter] = useState(false);
  const [liste, setListe] = useState("");
  const [visibleListe, setVisibleListe] = useState(false);
  const handleListeClose = () => _setVisibleListe();
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const handleConfirmClose = () => _setVisibleConfirm();
  const [modalIndicator, setModalIndicator] = useState(false);
  const handleIndicatorClose = () => setModalIndicator(false);
  const [modalGrafik, setModalGrafik] = useState(false);
  const handleGrafikClose = () => setModalGrafik(false);
  const [visibleListelerim, setVisibleListelerim] = useState(false);
  const handleListelerimClose = () => _setVisibleListelerim();

  const _setVisibleListelerim = () => {
    setVisibleListelerim(false);
    setListe("");
    setSelectListe(0);
  };
  const handleUyari = (name) => {
    toast.error(
      `${name} formülü özel Zamansallık Unsurları içermektedir. Premium+ paket özellikleri için 0530 238 60 18' e ulaşabilirsiniz.`
    );
  };
  const _listeSelect = (id) => {
    setSelectListe(id);
  };
  const funcListeEkle = async (form) => {
    console.log("form", form);
    setListeName(form.name);
    setListeNameId(parseInt(form.stock_id));
    await getListeler();
    setVisibleListelerim(true);
  };
  const handleSortType = (type) => {
    setSortType(type);
    setSortTypeChange("default");
  };
  function handleSortingChange(sorting, column) {
    // console.log("sorting", sorting, "column", column);
    let data = [];
    let newData = dataFilter.length > 0 ? dataFilter : dataBody2[0];
    if (sorting === "default") {
      setSortTypeChange("down");
      data = _.orderBy(newData, [column], ["desc"]);
    }
    if (sorting === "down") {
      setSortTypeChange("up");
      data = _.orderBy(newData, [column], ["asc"]);
    }
    if (sorting === "up") {
      setSortTypeChange("default");
      data = newData;
    }
    setDataBody([data]);
  }
  const _setVisibleListe = () => {
    setIsProcessing(false);
    setVisibleListe(false);
    setSelectedSembol([]);
    setSelectedBorsa("");
    setSelectedBorsaId(1);
    setListe("");
  };
  const _setVisibleConfirm = () => {
    setIsProcessing(false);
    setVisibleConfirm(false);
    setDeleteId(0);
    setDeleteName("");
  };
  const handleListeGuncelle = async () => {
    setIsProcessing(true);
    const postData = {
      access_token: user.access_token,
      id: updateId,
      name: liste,
      markets: selectedSembol,
      // j1407b: 1,
    };
    await postDataWithHeaders(UPDATELISTE, postData, dispatch).then((res) => {
      console.log("UPDATELISTE", res);
      if (res.code === 0) {
        setIsProcessing(false);
        setVisibleListe(false);
        setSelectedSembol([]);
        setSelectedBorsa("");
        setSelectedBorsaId(1);
        setListe("");
        if (listeId) {
          _getListe(listeId);
        } else {
          getListeler();
        }
        toast.success(res.data);
      } else {
        setVisibleListe(false);
        setIsProcessing(false);
        toast.error(res.data);
      }
    });
    setIsProcessing(false);
    setVisibleListe(false);
  };
  const handleListeEkle = async () => {
    setIsProcessing(true);
    const postData = {
      access_token: user.access_token,
      name: liste,
      markets: selectedSembol,
      // j1407b: 1,
    };
    await postDataWithHeaders(ADDLISTE, postData, dispatch).then((res) => {
      console.log("ADDLISTE", res);
      if (res.code === 0) {
        setIsProcessing(false);
        setVisibleListe(false);
        setSelectedSembol([]);
        setSelectedBorsa("");
        setSelectedBorsaId(1);
        setListe("");
        getListeler();
        toast.success(res.data);
      } else {
        setVisibleListe(false);
        setIsProcessing(false);
        toast.error(res.data);
      }
    });
    setIsProcessing(false);
    setVisibleListe(false);
  };
  const funcSelectBorsa = async (label, id) => {
    setSelectedBorsa(label);
    setSelectedBorsaId(id);
    setLoadData(true);
    let newMarket = [];
    let newValue = id;
    if (parseInt(newValue)) {
      newMarket = _.filter(markets.items, function (o) {
        return o.exchange_id == newValue;
      });
    } else {
      if (newValue == "XU030") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xu030 == 1;
        });
      }
      if (newValue == "XU050") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xu050 == 1;
        });
      }
      if (newValue == "XU100") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xu100 == 1;
        });
      }
      if (newValue == "XBANK") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xbank == 1;
        });
      }
      if (newValue == "XKTUM") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xktum == 1;
        });
      }
      // XUSIN, XUTEK, XILTM, XGIDA, XTRZM, XSGRT
      if (newValue == "XUSIN") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xusin == 1;
        });
      }
      if (newValue == "XUTEK") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xutek == 1;
        });
      }
      if (newValue == "XILTM") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xiltm == 1;
        });
      }
      if (newValue == "XGIDA") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xgida == 1;
        });
      }
      if (newValue == "XTRZM") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xtrzm == 1;
        });
      }
      if (newValue == "XSGRT") {
        newMarket = _.filter(markets.items, function (o) {
          return o.xsgrt == 1;
        });
      }
      if (newValue == "CIRCLE") {
        newMarket = _.filter(markets.items, function (o) {
          return o.circle == 1;
        });
      }
    }
    setMarketler(newMarket);
    setSearchMarket(newMarket);
  };

  const _funcSelectSembol = (id) => {
    if (selectedSembol.includes(id)) {
      setSelectedSembol(selectedSembol.filter((item) => item !== id));
    } else {
      setSelectedSembol([...selectedSembol, parseInt(id)]);
    }
    console.log(selectedSembol);
  };
  const funcSelectSembol = (id) => {
    const index = selectedSembol.indexOf(parseInt(id));
    if (index !== -1) {
      setSelectedSembol(selectedSembol.filter((item, idx) => idx !== index));
    } else {
      setSelectedSembol([...selectedSembol, parseInt(id)]);
    }
    console.log(selectedSembol);
  };
  function turkceIngilizceBuyukHarfCevir(metin) {
    const turkceHarfler = "çÇöÖıİşŞğĞüÜ";
    const ingilizHarfler = "ccooiissgguu";

    for (let i = 0; i < turkceHarfler.length; i++) {
      metin = metin.replace(
        new RegExp(turkceHarfler[i], "g"),
        ingilizHarfler[i]
      );
    }

    return metin.toUpperCase();
  }
  const _searchSembol = (text) => {
    setIsProcessing(true);
    let newMarkets = searchMarket;
    if (text) {
      newMarkets = _.filter(newMarkets, function (o) {
        return o.label.includes(turkceIngilizceBuyukHarfCevir(text));
      });
    }
    setSearch(text);
    setMarketler(newMarkets);
    setIsProcessing(false);
  };
  function marketCallback(item) {
    return {
      value: item.id,
      label: item.symbol,
      exchange_id: item.exchange_id,
      xu030: item.xu030,
      xu050: item.xu050,
      xu100: item.xu100,
      xbank: item.xbank,
      xktum: item.xktum,
      xusin: item.xusin,
      xutek: item.xutek,
      xiltm: item.xiltm,
      xgida: item.xgida,
      xtrzm: item.xtrzm,
      xsgrt: item.xsgrt,
      circle: item.circle,
    };
  }
  const _getMarkets = async () => {
    setIsProcessing(true);

    await postDataWithHeaders(
      MARKETS,
      { access_token: user.access_token },
      dispatch
    ).then((res) => {
      if (res.code === 0) {
        var dataDummy = [];
        dataDummy.push({
          items: res.data.map(marketCallback),
        });
        setMarkets(dataDummy[0]);
        let newMarket = [];
        let newValue = 1;
        newMarket = _.filter(dataDummy[0].items, function (o) {
          return o.exchange_id == newValue;
        });
        setMarketler(newMarket);
        setSearchMarket(newMarket);
        setIsProcessing(false);
      }
    });
  };

  async function _getIndicators(marketLists) {
    let date = new Date();
    let timestamp = date.getTime();
    const postData = {
      access_token: user.access_token,
      markets: marketLists,
    };
    let res = await postDataWithHeaders(
      INDICATORS + `?_=${timestamp}`,
      postData,
      dispatch
    );
    if (res.status === 200) {
      // test for status you want, etc
      //console.log(res.status);
    }
    // Don't forget to return something
    if (res.code === 0) {
      let resData = res.data;
      //console.log("resData Indicators", resData);
      console.log(
        "resData[0].indicators.length)",
        resData[0].indicators.length
      );
      localStorage.setItem("dataIndicator", JSON.stringify(resData));
      localStorage.setItem("stratejiAdet", resData[0].indicators.length);
      setDataIndicator(resData);
      setStratejiAdet(resData[0].indicators.length);
    }
  }
  const _getSembolName = (id) => {
    console.log("_getSembolName", id);
    let newMarket = _.filter(markets.items, function (o) {
      return o.value == id;
    });
    if (newMarket[0] == undefined) {
      return null;
    }
    return id != 0 ? newMarket[0].label : null;
  };

  const showListeDelete = (item) => {
    setVisibleConfirm(true);
    setDeleteName(item.name);
    setDeleteId(item.id);
  };
  const handleListeDelete = async () => {
    const postData = {
      access_token: user.access_token,
      id: deleteId,
      // j1407b: 1,
    };
    await postDataWithHeaders(DELETELISTE, postData, dispatch).then((res) => {
      if (res.code === 0) {
        toast.success(res.data);
        getListeler();

        setVisibleConfirm(false);
      } else {
        toast.error(res.data);
      }
    });
  };
  const funcIsProcessing = async (deger, text = "") => {
    console.log("funcIsProcessing", deger, text);
    setIsProcessing(deger);
  };
  const getListeler = async () => {
    const postData = {
      access_token: user.access_token,
      // j1407b: 1,
    };
    await postDataWithHeaders(GETLISTE, postData, dispatch).then((res) => {
      if (res.code === 0) {
        timeout(10000);
        setListeler(res.data);
      }
    });
  };
  const handleListemeEkle = async () => {
    if (selectListe == "new") {
      funcIsProcessing(true, "handleListemeEkle");
      const postData = {
        access_token: user.access_token,
        name: liste,
        markets: [listeNameId],
        // j1407b: 1,
      };
      await postDataWithHeaders(ADDLISTE, postData, dispatch).then((res) => {
        // console.log("ADDLISTE", res);
        if (res.code === 0) {
          funcIsProcessing(false);
          setVisibleListelerim(false);
          setListe("");
          setSelectListe(0);
          toast.success(res.data);
        } else {
          funcIsProcessing(false);
          setVisibleListelerim(false);
          toast.error(res.data);
        }
      });
      funcIsProcessing(false);
      setVisibleListelerim(false);
      return;
    }
    console.log("listeNameId", listeNameId);
    selectListe.markets.push(parseInt(listeNameId));
    const postData = {
      id: selectListe.id,
      access_token: user.access_token,
      name: selectListe.name,
      markets: selectListe.markets,
      // j1407b: 1,
    };
    // // console.log("postData", postData);
    await postDataWithHeaders(UPDATELISTE, postData, dispatch).then((res) => {
      // console.log("ADDLISTE", res);
      if (res.code === 0) {
        funcIsProcessing(false);
        setVisibleListelerim(false);
        setSelectListe("");
        setListeMainName("");
        setListeNameId(0);

        toast.success(res.data);
      } else {
        setVisibleListelerim(false);
        funcIsProcessing(false);
        setSelectListe("");
        setListeMainName("");
        setListeNameId(0);
        toast.error(res.data);
      }
    });
  };
  const _setNewListe = () => {
    setVisibleListe(true);
    setSelectedSembol([]);
    setListe("");
    setUpdateId(0);
    setUpdate(false);
  };
  const handleListeEdit = (item) => {
    setVisibleListe(true);
    setSelectedSembol(item.markets);
    setListe(item.name);
    setUpdateId(item.id);
    setUpdate(true);
  };
  async function _getTarama(favoritesStocks) {
    setIsProcessing(true);
    setDataFormation(null);
    const postData = {
      new_found: 0,
      bayz_just_closed: 0,
      exchange_id: [],
      yon: [],
      formasyon: JSON.stringify([]),
      periyot: JSON.stringify([]),
      markets: JSON.stringify([]),
      favorites: favoritesStocks,
      access_token: user.access_token,
      tur: "tarama",
    };
    let res = await postDataWithHeaders(
      TARAMAV2 + `?_=${timestamp}`,
      postData,
      dispatch
    );
    if (res.status === 200) {
      // test for status you want, etc
      console.log("status", res.status);
    }
    // Don't forget to return something
    // console.log("res", res.data);
    if (res.code === 0) {
      let resData = [];
      resData = _.groupBy(res.data.data, function (n) {
        return n.stock_code;
      });
      // resData = groupBy(res.data.data, function(n) {
      //   return n.stock_code;
      // });
      let dataIndicator = localStorage.getItem("dataIndicator");

      let filterDatas = [];
      if (dataIndicator) {
        let resDataIndicator = JSON.parse(dataIndicator);
        resDataIndicator.map((dataInd, ix) => {
          let bull = 0;
          let bear = 0;
          let formations = [];

          if (resData[dataInd.stock_code]) {
            formations = resData[dataInd.stock_code];
            bull = countMarket(resData[dataInd.stock_code]).BULL
              ? countMarket(resData[dataInd.stock_code]).BULL
              : countMarket(resData[dataInd.stock_code]).BEAR
              ? countMarket(resData[dataInd.stock_code]).BEAR * -1
              : 0;
            bear = countMarket(resData[dataInd.stock_code]).BEAR
              ? countMarket(resData[dataInd.stock_code]).BEAR
              : 0;
          }
          formations.length > 0 &&
            filterDatas.push({
              stock_id: dataInd.stock_id,
              name: dataInd.stock_code,
              exchange_id: dataInd.exchange_id,
              puan: dataInd.puan,
              bull: bull,
              bear: bear,
              indicators: dataInd.indicators,
              formations: formations,
            });
        });
      }

      //console.log("filterDatas", filterDatas);
      setDataFilter(filterDatas);
      setDataBody([filterDatas]);
      setDataBody2([filterDatas]);
      // localStorage.setItem("dataFilter", JSON.stringify(filterDatas));
      setIsProcessing(false);

      let hisseAdet = objectLength(resData);
      let taramaAdet = Object.values(resData).reduce(
        (count, current) => count + current.length,
        0
      );
      //console.log(hisseAdet, taramaAdet);
      setTaramaAdet(taramaAdet);
      setHisseAdet(hisseAdet);
      setLoadFilter(true);
    } else {
      setDataFormation(null);
      toast.error(res.data.data);
      setIsProcessing(0);
      if (res.data.code === "-1") {
        setInterval(() => {
          //window.location.href="/uye-cikis"
        }, 2000);
      }
    }
  }

  const _getListe = async (id) => {
    const postData = {
      access_token: user.access_token,
      id: id,
      // j1407b: 1,
    };
    await postDataWithHeaders(GETLISTE, postData, dispatch).then(
      async (res) => {
        if (res.code === 0) {
          console.log("liste", res.data);
          // setFavoritesStocks(res.data[0].markets);
          setListeItem(res.data[0]);
          setListeMainName(res.data[0].name);
          await _getIndicators(res.data[0].markets);
          await _getTarama(res.data[0].markets);
        }
      }
    );
  };
  const handleShowGrafik = (formation_id, loadChartType) => {
    setModalGrafik(true);
    // //console.log(formation_id, loadChartType)
    setFormationId(formation_id);
    setLoadChart(loadChartType);
  };
  const handleButton = (fMarket) => {
    let newColor = "default";
    if (fMarket == "BULL") {
      newColor = "success";
    } else {
      newColor = "danger";
    }
    return newColor;
  };
  const handleShowPuan = (hisseName) => {
    setModalIndicator(true);
    // //console.log(hisseName)
    setPuanName(hisseName);
  };
  const handleName = (name) => {
    return _.filter(updatedDataFormations, { slug: name }).map((v) => v.label);
  };
  useEffect(() => {
    async function fetchData() {
      await _getMarkets();
      await getListeler();
    }
    async function fetchDataListe() {
      await _getMarkets();
      await _getListe(listeId);
    }
    if (!listeId) {
      fetchData();
    } else {
      fetchDataListe();
    }
    console.log("listeId", listeId);
  }, [listeId]);

  useEffect(() => {
    if (listeId) {
      document.title = "Liste Detay Sayfası - Sirius Algo";
    } else {
      document.title = "Listelerim - Sirius Algo";
    }
  }, []);
  return (
    <Container className="Listelerim__container">
      <ToastContainer />
      {listeId ? (
        <Row className="Listelerim__row">
          <Col lg={12}>
            <div
              className="Listelerim__back"
              onClick={() => navigate("/listelerim")}
            >
              <IoMdArrowBack /> Listelerime Dön
            </div>
          </Col>
          <Col lg={12}>
            <div className="Listelerim__top">
              <div className="Listelerim__top__title">
                <span className="Listelerim__top__title__subname">Liste</span>
                <span className="Listelerim__top__title__name">
                  {listeMainName}
                </span>
              </div>
              <div
                className="Listelerim__button"
                onClick={() => handleListeEdit(listeItem)}
              >
                <SonucTekEkle color={"#3AB22F"} /> Listeye Ekle
              </div>
            </div>
          </Col>
          <Col lg={12}>
            <div className="Sonuclar_container">
              <div className="Sonuclar_left">
                <div className="Sonuclar_left__title">Sonuçlar</div>
                <div className="Sonuclar_left__text">
                  {hisseAdet} {hisseType} - {taramaAdet} Formasyon
                </div>
              </div>
              <div className="Sonuclar_right">
                <div className="Sonuclar_right__container">
                  <div
                    className={
                      sortType == 2
                        ? "Sonuclar_right__sort active"
                        : "Sonuclar_right__sort"
                    }
                    onClick={() =>
                      sortType == 2
                        ? handleSortingChange(sortTypeChange, "puan")
                        : handleSortType(2)
                    }
                  >
                    Stratejiye Göre Sırala
                    {sortType == 2 && (
                      <div className="Sonuclar_right__sort__icon">
                        {sortTypeChange == "default" ? (
                          <ArrowSort
                            color={theme.darkmode == "dark" ? "#fff" : "#000"}
                          />
                        ) : sortTypeChange == "up" ? (
                          <ArrowDown color="red" />
                        ) : (
                          <ArrowUp color="#5fb045" />
                        )}
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      sortType == 1
                        ? "Sonuclar_right__sort active"
                        : "Sonuclar_right__sort"
                    }
                    onClick={() =>
                      sortType == 1
                        ? handleSortingChange(sortTypeChange, "bull")
                        : handleSortType(1)
                    }
                  >
                    Boğaya Göre Sırala
                    {sortType == 1 && (
                      <div className="Sonuclar_right__sort__icon">
                        {sortTypeChange == "default" ? (
                          <ArrowSort
                            color={theme.darkmode == "dark" ? "#fff" : "#000"}
                          />
                        ) : sortTypeChange == "up" ? (
                          <ArrowDown color="red" />
                        ) : (
                          <ArrowUp color="#5fb045" />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="Sonuclar__body">
              {dataBody &&
                dataBody.map((data, index) =>
                  Object.values(data).map((forms, indix) => {
                    {
                      /* //console.log("forms", { forms }); */
                    }

                    let exchange_id = forms.exchange_id;
                    let puan = forms.puan;
                    return (
                      <div key={indix} className="Sonuclar__body__container">
                        <div className="Sonuclar__body__container__left">
                          {/* <SelectIcon
                                hisseName={forms.name}
                                fStocks={topluEkleLists}
                                func={handleTopluEkle}
                              /> */}

                          <SonucTekEkle
                            className="Sonuclar__body__container__left__close"
                            onClick={() => funcListeEkle(forms)}
                          />
                          {/* <SonucAlarm
                                className="Sonuclar__body__container__left__alarm"
                                onClick={() => funcAlarmFiyat(forms.name)}
                              /> */}
                          <Link
                            to={`/grafik-paneli/${forms.name}`}
                            target="_blank"
                          >
                            <SonucGrafik />
                          </Link>
                          <span className="Sonuclar__body__container__left__text">
                            {forms.name}
                          </span>
                          <div className="Sonuclar__body__container__left_border ml-auto"></div>
                        </div>

                        <div className="Sonuclar__body__container__right">
                          {forms.formations &&
                            forms.formations.map((formArray, indx) => {
                              let formasyonName = handleName(
                                formArray.formation
                              );
                              let foundItem = null;

                              if (user.bayz == "0") {
                                foundItem = zamansal.find(
                                  (element) => element === formasyonName[0]
                                );
                              }

                              let icon =
                                formArray.market === "BULL" ? (
                                  <ArrowUp color="#5fb045" />
                                ) : (
                                  <ArrowDown color="#E12222" />
                                );
                              let boxButton = handleButton(formArray.market);
                              let filePath = "";
                              return (
                                <Button
                                  key={indx}
                                  className={`Sonuclar__body__button  ${formArray.formation} btn-sm ${boxButton}`}
                                  onClick={() =>
                                    foundItem
                                      ? handleUyari(formasyonName[0])
                                      : handleShowGrafik(
                                          formArray.id,
                                          formArray.chart
                                        )
                                  }
                                  style={{
                                    marginLeft: 5,
                                    marginBottom: 5,
                                    // backgroundColor: `${handleColor(
                                    //   formArray.formationColor,
                                    //   formArray.formation,
                                    //   formArray.market
                                    // )}`,
                                  }}
                                >
                                  {icon} {handleName(formArray.formation)}{" "}
                                  {formArray.bayz_percentage
                                    ? `(${formArray.bayz_percentage}%)`
                                    : ""}{" "}
                                  {`(${formArray.period})`}
                                </Button>
                              );
                            })}
                        </div>
                        <div
                          className="Sonuclar__body__strateji"
                          onClick={() => handleShowPuan(forms.name)}
                        >
                          <span className="Sonuclar__body__strateji__text">
                            Strateji
                          </span>
                          <span className="Sonuclar__body__strateji__puan">
                            {puan}/{updateDataAll.length}
                          </span>
                        </div>
                      </div>
                    );
                  })
                )}
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <p>
                  BİST isim ve logosu "Koruma Marka Belgesi" altında korunmakta
                  olup izinsiz kullanılamaz, iktibas edilemez, değiştirilemez.
                  BİST ismi altında açıklanan tüm bilgilerin telif hakları
                  tamamen BİST'e ait olup, tekrar yayınlanamaz. Veriler{" "}
                  <img src={forinvest} width={90} /> tarafından
                  yayınlanmaktadır. Veriler <img src={forinvest} width={90} />{" "}
                  tarafından sağlanmaktadır. BİST hisse verileri 15 dakika
                  gecikmelidir.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Button
            className="Listelerim__add"
            onClick={() => {
              _setNewListe(true);
            }}
          >
            <SonucTekEkle color={"#fff"} /> Liste Oluştur
          </Button>
          <Col lg={12} className="Listelerim__lists">
            {listeler ? (
              listeler.map((item, index) => (
                <div key={index} className="Listelerim__list_item">
                  <div className="Listelerim__list_item__left">
                    <div className="Listelerim__list_item__left__title">
                      <span className="Listelerim__list_item__left__title__name">
                        {item.name}
                      </span>{" "}
                      <FiEdit size={24} onClick={() => handleListeEdit(item)} />
                    </div>
                    <div className="Listelerim__list_item__left__symbols">
                      {item.markets.map((market, indx) => (
                        <div
                          className="Listelerim__list_item__left__symbols__symbol"
                          key={indx}
                        >
                          {_getSembolName(market)}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="Listelerim__list_item__right">
                    <div className="Listelerim__list_item__right__remove">
                      <FaTrash
                        color="red"
                        onClick={() => showListeDelete(item)}
                        size={20}
                      />
                    </div>
                    <div
                      className="Listelerim__list_item__right__detail"
                      onClick={() => navigate("/listelerim/" + item.id)}
                    >
                      <BsStars size={24} /> Detayları Gör
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="OzelArama_lists_skelaton">
                <SkeletonTheme
                  baseColor={theme.darkmode == "dark" ? "#202020" : "#f0f0f0"}
                  highlightColor={theme.darkmode == "dark" ? "#444" : "#f4f4f4"}
                >
                  {[1, 2, 3, 4].map((i) => (
                    <Skeleton
                      key={i}
                      className="Listelerim__list_item"
                      style={{ width: "100%" }}
                    />
                  ))}
                </SkeletonTheme>
              </div>
            )}
          </Col>
        </Row>
      )}
      {visibleListe && (
        <Modal
          show={visibleListe}
          onHide={handleListeClose}
          dialogClassName="modal-80w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme.darkmode}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Liste Oluştur
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body wd-30">
                <Form.Control
                  type="text"
                  name="liste"
                  placeholder="Liste adı giriniz"
                  value={liste}
                  onChange={(e) => setListe(e.target.value)}
                  className="modalAlarmDiv__body__input"
                />
              </div>
            </div>
            <div className="modalSelectButtons">
              {dataBorsa.map((borsa) => (
                <Button
                  key={borsa.label}
                  className={
                    selectedBorsa == borsa.label
                      ? "modalSelectButton active"
                      : "modalSelectButton"
                  }
                  onClick={() =>
                    funcSelectBorsa(
                      selectedBorsa == borsa.label ? "" : borsa.label,
                      borsa.value
                    )
                  }
                >
                  {borsa.label}
                  {selectedBorsa == borsa.label && (
                    <span className="modalSelectButton__close">
                      <ButtonClose />
                    </span>
                  )}
                </Button>
              ))}
            </div>
            <div className="modalSearch">
              <div className="modalSearch__input">
                <BsSearch color="#fff" />
                <input
                  type="text"
                  placeholder="Arama Yap"
                  value={search}
                  onChange={(e) => _searchSembol(e.target.value)}
                />
              </div>
            </div>
            <div className="modalSelecteds">
              {selectedSembol.length > 0 &&
                selectedSembol.map((sembol) => {
                  return (
                    <div
                      key={sembol}
                      className="modalSelectButton active"
                      onClick={() => funcSelectSembol(sembol)}
                    >
                      {_getSembolName(sembol)}
                      <span className="modalSelectButton__close">
                        <ButtonClose />
                      </span>
                    </div>
                  );
                })}
            </div>

            <div className="modalSelectButtons">
              {marketler.map((sembol) => (
                <Button
                  key={sembol.label}
                  className={
                    selectedSembol == sembol.label
                      ? "modalSelectButton active"
                      : "modalSelectButton"
                  }
                  onClick={() => funcSelectSembol(sembol.value)}
                >
                  {sembol.label}
                </Button>
              ))}
            </div>
            <Button
              onClick={() =>
                update ? handleListeGuncelle() : handleListeEkle()
              }
              className="modalSonucButton"
            >
              {isProcessing ? (
                <PulseLoader color="#fff" aria-label="Liste kaydediliyor..." />
              ) : update ? (
                "Listeyi Güncelle"
              ) : (
                "Listeyi Kaydet"
              )}
            </Button>
          </Modal.Body>
        </Modal>
      )}
      {visibleConfirm && (
        <Modal
          show={visibleConfirm}
          onHide={handleConfirmClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme.darkmode}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    Liste Sil
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalAlarmDiv">
              <div className="modalAlarmDiv__body">
                <Form.Control
                  type="text"
                  disabled
                  value={deleteName}
                  className="modalAlarmDiv__body__input"
                />
              </div>
            </div>
            <div className="modalAlarmDivButtons">
              <Button
                onClick={() => setVisibleConfirm(false)}
                className="modalSonucButton"
                variant="secondary"
              >
                İptal
              </Button>
              <Button
                variant="danger"
                onClick={() => handleListeDelete()}
                className="modalSonucButton"
              >
                Sil
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {modalIndicator && (
        <Modal
          show={modalIndicator}
          onHide={handleIndicatorClose}
          dialogClassName="modal-80w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <PuanComponent
              urlApi={urlApi}
              name={puanName}
              access_token={user.access_token}
            />
          </Modal.Body>
        </Modal>
      )}
      {visibleListelerim && (
        <Modal
          show={visibleListelerim}
          onHide={handleListelerimClose}
          dialogClassName="modal-30w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          theme={theme}
        >
          <Modal.Header closeButton>
            <Container>
              <Row>
                <Col>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="modalTitle"
                  >
                    {listeName} Ekle
                  </Modal.Title>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="modalListeTabDiv">
              <div className="modalListeTabDiv__body">
                <div
                  className={
                    selectListeTab == 1
                      ? "modalListeTabDiv__body__tabs active"
                      : "modalListeTabDiv__body__tabs"
                  }
                  onClick={() => setSelectListeTab(1)}
                >
                  Listelerime Ekle
                </div>
                <div
                  className={
                    selectListeTab == 2
                      ? "modalListeTabDiv__body__tabs active"
                      : "modalListeTabDiv__body__tabs"
                  }
                  onClick={() => setSelectListeTab(2)}
                >
                  Favorilerime Ekle
                </div>
              </div>
            </div>
            <div className="modalListeTabDiv__list">
              {selectListeTab == 1 ? (
                <>
                  <div className="modalListeScroll">
                    {listeler &&
                      listeler.map((item) => (
                        <div
                          key={item.id}
                          className="modalListeTabDiv__list__item"
                          onClick={() => _listeSelect(item)}
                        >
                          {selectListe.id == item.id ? (
                            <SonucSecildi />
                          ) : (
                            <SonucSec />
                          )}
                          <div>{item.name}</div>
                        </div>
                      ))}
                  </div>
                  <div
                    className="modalListeTabDiv__list__item"
                    onClick={() => _listeSelect("new")}
                  >
                    {selectListe == "new" ? <SonucSecildi /> : <SonucSec />}
                    <Form.Control
                      type="text"
                      name="liste"
                      placeholder="Yeni Liste Oluştur"
                      value={liste}
                      onChange={(e) => setListe(e.target.value)}
                      className="modalAlarmDiv__body__input"
                    />
                  </div>
                  <Button
                    onClick={() => handleListemeEkle()}
                    className="modalSonucButton"
                    style={{ width: 215 }}
                  >
                    {isProcessing ? (
                      <PulseLoader
                        color="#fff"
                        aria-label="Sonuçlar Getiriliyor..."
                      />
                    ) : (
                      "Listeye Ekle"
                    )}
                  </Button>
                </>
              ) : (
                <></>
              )}
              {selectListeTab == 2 && (
                <>
                  <Button
                    onClick={() => handleFavoriEkle()}
                    className="modalSonucButton"
                    style={{ width: 215 }}
                  >
                    {isProcessing ? (
                      <PulseLoader
                        color="#fff"
                        aria-label="Sonuçlar Getiriliyor..."
                      />
                    ) : (
                      "Favoriye Ekle"
                    )}
                  </Button>
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
      {modalGrafik && (
        <Modal
          show={modalGrafik}
          onHide={handleGrafikClose}
          dialogClassName="modal-80w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <ChartComponent
              urlApi={urlApi}
              id={formationId}
              load={loadChart}
              theme={theme.darkmode}
              access_token={user.access_token}
            />
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
}

export default Listelerim;
