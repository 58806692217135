import React from "react";
import "./Sozlesmeler.css";
import { Container, Row, Col } from "react-bootstrap";
import SideMenu from "../SideMenu/SideMenu";
import Header from "../System/header";

function SssScreen() {
  return (
    <Container fluid>
      <Row>
        <Col lg={2}>
          <SideMenu />
        </Col>
        <Col lg={10}>
          <div className="DashBoard__header">
            <Header />
          </div>
          <div className="DashBoard_container sozlesmeler">
            <h1>Sıkça Sorulan Sorular</h1>

            <h3>SİRİUS ALGO’YA NASIL KAYIT OLABİLİRİM ?</h3>

            <p>
              Üye kayıt bölümünden cep telefonu numaranızı girerek kaydınızı
              oluşturabilir ve “Paket Satın Al” bölümünden Aylık/6Aylık paket
              seçeneklerinden dilediğinizi seçerek Sirius Eko Sistemi’nde
              analizlere başlayabilirsiniz.
            </p>

            <h3>Premium paket ve Premium+ paket arasındaki fark nedir ?</h3>

            <p>
              Premium+ pakette Zamansallık Teorisi (bayZ/Gölge) sorgularına
              ulaşabilir ve analizlerinizde kullanabilirsiniz. Zamansallık
              analizlerine ulaşmak için{" "}
              <a href="http://www.orphanakademi.com" target="_blank">
                www.orphanakademi.com
              </a>{" "}
              üzerinden Zamansallık Teorisi Eğitimi’ne katılmanız gereklidir.
            </p>

            <h3>
              Mevcut bir paketim varken Sirius’a gelen yeni güncellemelerden
              yararlanabiliyor muyum?
            </h3>

            <p>
              Sirius Algo içerisinde paketiniz olduğu sürece, yeni
              güncellemelerin tümü ücretsiz olarak hesabınızda
              aktifleştirilecektir.
            </p>

            <h3>
              Mevcut bir paketim varken yeni bir paket alarak süreyi uzatmak
              istersem ne oluyor ?
            </h3>

            <p>
              Yeni paketiniz, mevcut paket sürenizin üzerine eklenir. Örnek : 6
              aylık paketinizden son 5 gün kaldığında, yeniden 6 aylık paket
              alırsanız Toplam Paket Süreniz: 6 ay+ 5 gün olarak 185 gün’ e
              yükselecektir.
            </p>

            <h3>
              Mevcuttaki “Premium” paketimi “Premium+” (Zamansallık içeren)
              pakete nasıl yükseltebilirim?
            </h3>

            <p>
              <a href="http://www.orphanakademi.com" target="_blank">
                www.orphanakademi.com
              </a>{" "}
              üzerinden Zamansallık Teorisi Eğitimi’ni satın alarak “Premium+”
              paketin kilidini açabilirsiniz. <b>“Paket Satın Al”</b> bölümünden
              Premium+ paketinizi seçip satın alabilirsiniz.{" "}
            </p>
            <p>
              Premium+ paket alımı yaptığınızda mevcut paketinizde kalan süreniz
              varsa, paketler arasındaki fark oranına göre gün sayısı hesaplanır
              ve yeni paketinize eklenir. (Örnek: Premium paketinizde 5 gün
              kalmışken Premium+ paket satın alımı yaparsanız, eski paketinizden
              kalan 5 gün, yeni paketinize 3 gün olarak eklenecektir.)
            </p>

            <h3>Sirius Algo üzerindeki fiyatlar “Anlık Fiyat” mıdır ?</h3>

            <p>
              Kripto Paralar ve Foreks Pariteleri dışındaki verilerimiz 15 dk
              gecikmeli gelmektedir. Şimdilik 😊
            </p>

            <h3>
              Sirius Algo üzerinde grafikte kendi çizimlerimi yapabilir miyim ?
            </h3>

            <p>
              Evet, grafik paneli sekmemizden Tradingview grafik ekranında kendi
              çizimlerinizi yapabilir ve kaydedebilirsiniz.
            </p>

            <h3>
              Paket sürem bittiğinde kartımdan otomatik ödeme çekilecek mi ?
            </h3>

            <p>
              Hayır, Sirius paketiniz bittiğinde otomatik ödeme uygulanmaz. Siz
              kendi isteğinizle tekrardan paket satın alımı yapabilirsiniz.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SssScreen;
