import React, { useState, useEffect } from "react";
import { Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useSelector } from "react-redux";

import { TickerTape } from "react-ts-tradingview-widgets";

const taperData = [
  {
    description: "BIST 100",
    proName: "BIST:XU100",
  },
  {
    description: "GRAM ALTIN",
    proName: "FX_IDC:XAUTRYG",
  },
  {
    description: "DOLAR",
    proName: "FX:USDTRY",
  },
  {
    description: "EURO",
    proName: "FX:EURTRY",
  },
  {
    description: "ONS ALTIN",
    proName: "FOREXCOM:XAUUSD",
  },
  {
    description: "NASDAQ",
    proName: "NASDAQ:NDX",
  },
  {
    description: "DOW JONES",
    proName: "FOREXCOM:DJI",
  },
];

function Header() {
  const theme = useSelector((state) => state.theme.darkmode);
  const { user } = useSelector((state) => state.auth);
  return user.email == "tradingview@bayz.app" ? null : (
    <TickerTape
      symbols={taperData}
      displayMode="compact"
      isTransparent={true}
      colorTheme={theme == "light" ? "light" : "dark"}
    ></TickerTape>
  );
}

export default Header;
